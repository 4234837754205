import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"

const Privacy = () => {

    const i18n = useIntl().formatMessage

    return (
    <>
        <SEO title={ i18n({ id: "pages.privacy.title" })} />
        <section className="section-padding">
            <div className="container">
                <div className="row center-xs">
                <div className="col col-xs-12 col-md-6">
                    <h1>{ i18n({ id: "pages.privacy.title" })}</h1>
                    <h2>{ i18n({ id: "pages.privacy.subtitle_one" })}</h2>
                    <p>{ i18n({ id: "pages.privacy.text_one" })}</p>
                    <h2>{ i18n({ id: "pages.privacy.subtitle_two" })}</h2>
                    <p>{ i18n({ id: "pages.privacy.text_two" })}</p>
                    <h2>{ i18n({ id: "pages.privacy.subtitle_three" })}</h2>
                    <p>{ i18n({ id: "pages.privacy.text_three" })}</p>
                    <h2>{ i18n({ id: "pages.privacy.subtitle_four" })}</h2>
                    <p>{ i18n({ id: "pages.privacy.text_four" })}</p>
                    <h2>{ i18n({ id: "pages.privacy.subtitle_five" })}</h2>
                    <p>{ i18n({ id: "pages.privacy.text_five" })}</p>
                    <h2>{ i18n({ id: "pages.privacy.subtitle_six" })}</h2>
                    <p>{ i18n({ id: "pages.privacy.text_six" })}</p>
                    <h2>{ i18n({ id: "pages.privacy.subtitle_seven" })}</h2>
                    <p>{ i18n({ id: "pages.privacy.text_seven" })}</p>
                    <h2>{ i18n({ id: "pages.privacy.subtitle_eight" })}</h2>
                    <p>{ i18n({ id: "pages.privacy.text_eight" })}</p>
                    <h2>{ i18n({ id: "pages.privacy.subtitle_nine" })}</h2>
                    <p>{ i18n({ id: "pages.privacy.text_nine" })}</p>
                </div>
                </div>
            </div>
        </section>
    </>
    )
    }

export default Privacy
